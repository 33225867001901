<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.FullName || userData.username }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
        <span class="user-status">{{ fantasyName }}</span>
      </div>
      <b-avatar
        size="40"
        :style="{backgroundColor: `${getInitialsUser.bgColor}` }"
      >
        <!-- <feather-icon
          v-if="!userData.FullName"
          icon="UserIcon"
          size="22"
        /> -->
        <span>{{ getInitialsUser.initials }}</span>
      </b-avatar>
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Perfil</span>
    </b-dropdown-item> -->

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Configurações</span>
    </b-dropdown-item>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Sair</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar,
} from 'bootstrap-vue'
import jwtDecode from 'jwt-decode'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  computed: {
    fantasyName() {
      return jwtDecode(window.localStorage.getItem('accessToken')).FantasyName
    },
    getInitialsUser() {
      const colors = ['#1abc9c', '#2ecc71', '#3498db', '#9b59b6', '#34495e', '#16a085', '#27ae60', '#2980b9', '#8e44ad', '#2c3e50', '#f1c40f', '#e67e22', '#e74c3c', '#95a5a6', '#f39c12', '#d35400', '#c0392b', '#bdc3c7', '#7f8c8d']
      const initials = this.userData.FullName.split(' ').length > 1 ? this.userData.FullName.split(' ')[0].charAt(0).toUpperCase() + this.userData.FullName.split(' ')[1].charAt(0).toUpperCase() : this.userData.FullName.split(' ')[0].charAt(0).toUpperCase()
      // const initials = this.userData.FullName.split(' ')[0].charAt(0).toUpperCase() + this.userData.FullName.split(' ')[1].charAt(0).toUpperCase()

      const charIndex = initials.charCodeAt(0) - 65
      const colorIndex = charIndex % 19

      const bgColor = colors[colorIndex]

      return {
        initials, bgColor,
      }
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })

      const bc = new BroadcastChannel('indiquei')
      bc.postMessage('logout')
    },
  },
}
</script>
