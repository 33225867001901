export default [
  {
    title: 'Resultados',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Conversão',
        route: 'dashboard-idq',
        resource: 'conversionDashboard',
        action: 'read',
      },
      {
        title: 'Conversão (Beta)',
        route: 'dashboard-conversion',
        resource: 'conversionDashboard',
        action: 'read',
      },
    ],
  },
]
