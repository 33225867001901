export default [
  {
    header: 'Apps',
  },
  {
    icon: 'FolderPlusIcon',
    title: 'Campanhas',
    route: 'apps-campaigns',
    children: [
      {
        title: 'Lista de campanhas',
        route: 'campaigns-list',
        resource: 'campaign',
        action: 'read',
      },
      {
        title: 'Nova campanha',
        route: 'campaign-new',
        resource: 'campaign',
        action: 'edit',
      },
    ],
  },
  {
    icon: 'UsersIcon',
    title: 'Indicações',
    route: 'apps-indicates',
    resource: 'indications',
    action: 'read',
  },
  {
    icon: 'AwardIcon',
    title: 'Validar cupom',
    route: 'validate-voucher',
    resource: 'indications',
    action: 'edit',
  },
  {
    icon: 'GiftIcon',
    title: 'Recompensas',
    route: 'apps-rewards',
    children: [
      {
        title: 'Em dinheiro',
        route: 'rewards-money',
        resource: 'rewardMoney',
        action: 'read',
      },
      {
        title: 'Em cupom',
        route: 'rewards-voucher',
        resource: 'rewardVoucher',
        action: 'read',
      },
    ],
  },
  {
    icon: 'BriefcaseIcon',
    title: 'Empresa & Conta',
    route: 'company-config',
    resource: 'settingBusiness',
    action: 'read',
  },
  // {
  //   icon: 'DollarSignIcon',
  //   title: 'Plano e pagamento',
  //   children: [
  //     {
  //       title: 'Meu plano',
  //       route: 'company-plan',
  //       resource: 'settingFinancial',
  //       action: 'read',
  //     },
  //     {
  //       title: 'Formas de pagamento',
  //       route: 'credit-card-list',
  //       resource: 'settingFinancial',
  //       action: 'read',
  //     },
  //   ],
  // },
  {
    icon: 'UserIcon',
    title: 'Usuários',
    children: [
      {
        title: 'Cadastrar usuário',
        route: 'users-new',
        resource: 'configUser',
        action: 'edit',
      },
      {
        title: 'Usuários do painel',
        route: 'users-list',
        resource: 'configUser',
        action: 'read',
      },
      {
        title: 'Perfis de acesso',
        route: 'profiles-list',
        resource: 'configProfile',
        action: 'read',
      },
    ],
  },

]
